import React from 'react'
import ReactDOM from 'react-dom/client'
// import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { MantineProvider } from '@mantine/core'

import { BrowserRouter, Routes, Route } from "react-router-dom";

import theme from './theme'

import Survey from './pages/Survey'
// import Admin from './pages/Admin'

// import { Global, css } from '@emotion/react'



ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    {/*<Global styles={styles} />*/}
    <MantineProvider theme={theme}>
      {/*<ColorModeScript initialColorMode={theme.config.initialColorMode} />*/}
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Survey />} />
          </Routes>
      </BrowserRouter>
    </MantineProvider>
  </React.StrictMode>
)
