import { useCallback, useState } from 'react'
import Blinds from '../components/Blinds'


// import { Box } from '@chakra-ui/react'
// import { Flex } from '@chakra-ui/react'
// import { Heading } from '@chakra-ui/react'
// import { DarkMode, LightMode } from '@chakra-ui/react'
// import { Button } from '@chakra-ui/react'

import { Flex, Button, Box, Paper, Title, Text, MantineProvider } from '@mantine/core'

import { Radio, Textarea } from '@mantine/core'
import { forwardRef } from 'react'

import { useBlinds } from '../model/blinds'

// import { Text } from '@chakra-ui/react'

// import { keyframes } from '@chakra-ui/react'


// import { chakra } from '@chakra-ui/react'

//JSX.LibraryManagedAttributes<T, React.ComponentProps<T>

// type AnyComponent = keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>
// const bindProps = <T extends AnyComponent>(Component: T, boundProps: Omit<React.ComponentProps<T>, "children">) =>
//   forwardRef(({children, ...props}: React.ComponentProps<T>, ref) =>
//     <Component {...boundProps} {...props}>{children}</Component>)


// const LightMode = forwardRef(({children, ...props}: React.ComponentProps<typeof MantineProvider>, ref) =>
//   <MantineProvider inherit theme={{primaryColor: 'gray'}} {...props}>{children}</MantineProvider>)

// const DarkMode = forwardRef(({children, ...props}: React.ComponentProps<typeof MantineProvider>, ref) =>
//   <MantineProvider inherit theme={{primaryColor: 'dark'}} {...props}>{children}</MantineProvider>)

// const LightMode = bindProps(MantineProvider, {inherit: true, theme:{primaryColor: 'gray'}})

// const DarkMode = bindProps(MantineProvider, {inherit: true, theme:{primaryColor: 'dark'}})

const LightMode = forwardRef(({children, ...props}: React.ComponentProps<typeof MantineProvider>, ref) =>
  <MantineProvider inherit theme={{primaryColor: 'dark', colorScheme: 'light'}} {...props}>{children}</MantineProvider>)

const DarkMode = forwardRef(({children, ...props}: React.ComponentProps<typeof MantineProvider>, ref) =>
  <MantineProvider inherit theme={{colorScheme: 'dark'}} {...props}>{children}</MantineProvider>)



function HeroText() {
  return <Flex justify="center" align="center" h="100%">
    <Title fz="33vw" color="white">FLEX</Title>
  </Flex>
}

/*
    font-family: var(--chakra-fonts-body);
    color: var(--chakra-colors-chakra-body-text);
    background: var(--chakra-colors-chakra-body-bg);
    transition-property: background-color;
    transition-duration: var(--chakra-transition-duration-normal);
    line-height: var(--chakra-lineHeights-base);
*/


import create from 'zustand'


// const fixColors = {
//   bg: "chakra-body-bg",
//   color:"chakra-body-text",
// }

function Home() {
  const start = useStore((state) => state.start)

  return <Flex h="100%" direction="column" justify="space-around" py="lg" pos="relative">
    <HeroText />
    <Flex justify="center">
        <Button size="xl" variant="outline" radius="xl" onClick={start}>START</Button>
    </Flex>
  </Flex>
}

function Consent() {
  const decline = useStore((state) => state.decline)
  const accept = useStore((state) => state.accept)
  const prepareBlinds = useBlinds((state) => state.prepare)

  const onAccept = useCallback(() => {
    prepareBlinds()
    accept()
  }, [])

  return <Flex h="100%" direction="column" align="center" justify="center" pos="relative">
    <LightMode>
    <Paper radius="xl" w="50%" h="50%" p="sm">
      <Flex w="100%" h="100%" direction="column" align="center" px="lg" py="sm">
        <Text size="lg" sx={{flex: 1}} w="100%">
          Consent consent consent consent consent consent consent consent consent consent consent
          consent consent consent consent consent consent consent consent consent consent consent
          consent consent consent consent consent consent consent consent consent consent consent
          consent consent consent consent consent consent consent consent consent consent consent?
        </Text>
        <Flex w="40%" justify="space-between">
            <Button size="md" radius="xl" variant="outline" bg="chakra-body-bg" onClick={onAccept}>Accept</Button>
            <Button size="md" radius="xl" variant="outline" bg="chakra-body-bg" onClick={decline}>Decline</Button>
        </Flex>
      </Flex>
    </Paper>
    </LightMode>
  </Flex>
}

type QuestionSeriesProps = {
  pos: number,
  title: string,
  onDone?: () => void,
  disabled?: boolean
}

function QuestionSeries({pos, title, onDone, disabled}: QuestionSeriesProps) {
  const [step, setStep] = useState(0)
  const [answered, setAnswered] = useState(false)
  const [valid, setValid] = useState(true)

  const [blindAnswer, setBlindAnswer] = useState([0, 0])

  const nextStep = useCallback(() => {
    if (step === 3) {
      setStep(0)
      setAnswered(true)
      onDone?.()
    } else {
      if (step == 2) {
        const [pos, v] = blindAnswer
        console.log('pos', pos, 'value', v)
        triggerBlinds(pos, v)
      }

      setStep(step + 1)
      setValid(false)
    }

  }, [step, blindAnswer])

  const triggerBlinds = useBlinds(state => state.answer)

  const onAnswer = useCallback((v: string) => {
    const v2 = (+v-1)/4
    console.log('setBlindAnswer', 'pos', pos, 'value', v2)
    setBlindAnswer([pos, v2])
    setValid(true)
  }, [pos])

  const onChange = useCallback(() => {
    setValid(true)
  }, [])

  return <Flex
    direction="column"
    w="20%"
    // border="1px solid red"
    pos="absolute"
    left={24.333+(pos)*26.666 + '%'}
    top="20%"
    align="start"
    gap="md"
  >
    <Title size="24px" h="calc(24px * 2 * 1.3)" sx={{textTransform: "uppercase", display: "flex", alignItems: "end"}}>
      {title}
    </Title>

    {step > 0 &&
      <Radio.Group
        label="Should the government fund body modifications for a specific purpose?"
        name={`radio-${pos}-1`}
        orientation="vertical"
        spacing={0}

        sx={{
          '.mantine-RadioGroup-label': { fontWeight: 400 },
        }}
        onChange={onChange}
      >
        <Radio value="1" label="Yes" disabled={step !== 1} />
        <Radio value="0" label="No" disabled={step !== 1} />
      </Radio.Group>
    }

    {step > 1 &&
      <Radio.Group
        label="Should people be allowed to modify their bodies to enhance basic human traits such as intelligence?"
        name={`radio-${pos}-2`}
        orientation="vertical"
        spacing={0}
        sx={{
          '.mantine-RadioGroup-label': { fontWeight: 400 },
        }}
        onChange={onAnswer}
      >
        <Radio value="5" label="Definitely Should" disabled={step !== 2} />
        <Radio value="4" label="Probably Should" disabled={step !== 2} />
        <Radio value="3" label="Don't Know" disabled={step !== 2} />
        <Radio value="2" label="Probably Shouldn't" disabled={step !== 2} />
        <Radio value="1" label="Definitely Shouldn't" disabled={step !== 2} />
      </Radio.Group>
    }
    {step > 2 &&
      <Textarea
        label="Can adolescents give informed consent regarding surgical body modification?"
        // placeholder="Autosize with no rows limit"
        sx={{
          label: { fontWeight: 400 },
        }}
        maxLength={100}
        rows={3}
        disabled={step !== 3}

        onChange={onChange}
      />
    }

    <Button size="sm" radius="xl" color="dark" onClick={nextStep} disabled={disabled || answered || !valid}>
      { answered ? "Answered" : "Answer" }
    </Button>
  </Flex>
}


type QuestionsProps = {
  onDone?: () => void,
}

function Questions({onDone}: QuestionsProps) {
  // const decline = useStore((state) => state.decline)
  const done = useStore((state) => state.done)
  const resetBlinds = useBlinds((state) => state.reset)

  const [doneCount, setDoneCount] = useState(0)
  const incDoneCount = useCallback(() => {
    if (doneCount === 3) return
    const count = doneCount + 1
    setDoneCount(count)
  }, [doneCount])

  const reset = useCallback(() => {
    resetBlinds()
    done()
    onDone?.()
  }, [onDone])

  return <Flex h="100%" direction="column" align="center" justify="center" py="10" pos="relative">
    <QuestionSeries title="Body Modification" pos={0} onDone={incDoneCount} />
    <QuestionSeries title="Pain" pos={1} onDone={incDoneCount} disabled={doneCount < 1} />
    <QuestionSeries title="Human Capability" pos={2} onDone={incDoneCount} disabled={doneCount < 2} />

    { doneCount === 3 &&
      <Flex justify="end" align="end" sx={{flex: 1}} w="100%" p="md">
        <Button size="md" radius="xl" color="dark" onClick={reset}>Reset</Button>
      </Flex>
    }
  </Flex>
}
type ScreenComponent = typeof Home | typeof Consent

interface StoreState {
  screen: keyof typeof screens
  start: () => void
  decline: () => void
  accept: () => void
  done: () => void
}

const useStore = create<StoreState>()((set) => ({
  screen: 'home',
  start: () => set(() => ({ screen: 'consent' })),
  decline: () => set(() => ({ screen: 'home' })),
  accept: () => set(() => ({ screen: 'questions' })),
  done: () => set(() => ({ screen: 'home' })),
}))


const screens = {
  home: Home,
  consent: Consent,
  questions: Questions
}

function Survey() {
  // const [{Screen}, setScreen] = useState({Screen: Home})
  const screen = useStore((state) => state.screen)

  const [key, setKey] = useState(0)
  const nextKey = useCallback(() => {
    setKey(k => k + 1)
  }, [])

  const Screen = screens[screen]


  const wblinds: number[] = []
  const bblinds: number[] = []

  const Mode = (Screen === Home || Screen === Consent) ? DarkMode : LightMode
  // const Screen = screen

  return <Mode>
    <Paper w="100%" h="100%" withBorder>
      <Blinds wblinds={wblinds} bblinds={bblinds} />
      <Screen key={key} onDone={nextKey} />
    </Paper>
  </Mode>
}

export default Survey
