import create from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { useEffect } from 'react'

export type Blind = {
  position: number,
  target: number,
}

export const N_WHITE = 28
export const N_BLACK = 3

const REAL_HEIGHT = 3000

// units per second
const REAL_SPEED = 60
// const REAL_SPEED = 300

export const SPEED = REAL_SPEED/REAL_HEIGHT


const clamp = (v: number, vmin: number, vmax: number) => Math.max(vmin, Math.min(vmax, v))

export const resolvePosition = ({position, target}: Blind, t: number, newt: number) => {
  const dt = newt - t
  const dist = SPEED * dt

  const diff = target - position
  const newPosition = position + clamp(diff, -dist, dist)

  // console.log('t', t, 'newt', newt, dt)

  return newPosition
}

export const resolveState = (state: State, newt: number) => {
  const {t} = state
  for (const blind of state.white) {
    blind.position = resolvePosition(blind, t, newt)
  }
  for (const blind of state.black) {
    blind.position = resolvePosition(blind, t, newt)
  }

  state.t = newt
}


export type State = {
  t: number,
  mode: string,
  white: Blind[],
  black: Blind[],
}

export type Actions = {
  update: (newState: State) => void,
  prepare: () => void,
  answer: (bi: number, n: number) => void,
  reset: () => void,
}

export type Store = State & Actions

const mapn = <T,>(length: number, f: (i: number) => T) => Array.from({length}, (_, i) => f(i))


const mkBlind = (position: number, target: number = position) => ({position, target})


const WRANGE = Math.ceil(N_WHITE/N_BLACK)

const wforb = (bi: number) => {
  // const range = N_WHITE/(N_BLACK+0.5)


  const wi = (bi + 0.5) / N_BLACK * (N_WHITE-1)
  const first = Math.ceil(wi - WRANGE/2)
  const last = Math.floor(wi + WRANGE/2)

  return mapn(WRANGE, i => first + i)
}

const S = 2
const scale = (x: number) => (S**(x*x) - 1) / (S - 1)

const MAXY = 3.5/3.5
const MINY = 1.5/3.5

const scaleY = (y: number) => y * (MAXY-MINY) + MINY

export const useBlinds = create(immer<Store>((set) => ({
  t: 0,
  mode: 'random',
  white: mapn(N_WHITE, () => mkBlind(scaleY(Math.random()))),
  black: mapn(N_BLACK, () => mkBlind(scaleY(0))),

  prepare() {
    set((draft) => {
      draft.mode = 'manual'
      resolveState(draft, Date.now() / 1000)
      for (const blind of draft.black) blind.target = scaleY(0.48)
      for (const blind of draft.white) blind.target = scaleY(0)
    })
  },

  answer(bi: number, n: number) {
    set((draft) => {
      draft.mode = 'manual'
      resolveState(draft, Date.now() / 1000)
      const {white, black} = draft
      black[bi].target = scaleY(n)

      const wis = wforb(bi)
      for (const [i, wi] of wis.entries()) {
        // const dist = Math.ceil(Math.abs(i - (WRANGE/2-0.5)))
        const dist = Math.ceil(Math.abs(i*2 + 1 - WRANGE))/WRANGE
        console.log('wi', wi, 'dist', dist, 'target', n*dist)
        white[wi].target = scaleY(n * (1 - scale(dist)))
      }

      for (let i = 1; i < N_BLACK; i++) {
        const wi2 = Math.floor(i/N_BLACK * (N_WHITE-1))
        white[wi2].target = (white[wi2-1].target + white[wi2+1].target) / 2
      }

    })
  },

  update(newState: State) {
    return newState
  },

  reset() {
    set((draft) => {
      draft.mode = 'random'

      for (const blind of draft.black) blind.target = 0
    })
  }
})))


// let dummyBlinds: State = {
//   t: 0,
//   mode: 'random',
//   white: mapn(N_WHITE, () => mkBlind(0, 0)),
//   black: mapn(N_BLACK, () => mkBlind(0, 0))
// }

export const useDummyBlinds = () => {
  const store = useBlinds()

  useEffect(() => {

    let timer = setInterval(() => {
      if (useBlinds.getState().mode === 'random') {
        useBlinds.setState((draft) => {
          resolveState(draft, Date.now() / 1000)

          for (let i = 0; i < 10; i++) {
            const n = Math.floor(Math.random() * N_WHITE)
            draft.white[n].target = scaleY(Math.random())
          }
        })
      }
    }, 1000)

    const unsubscribe = () => {
      clearTimeout(timer)
    }
    return unsubscribe
  }, [store])

  return store
}
