// import { extendTheme, defineStyleConfig, defineStyle } from '@chakra-ui/react'

const fullSize = { width: '100%', height: '100%' }

// const Heading = defineStyleConfig({
//   variants: {
//     hero: {
//       fontSize: '33vw',
//       textTransform: 'uppercase',
//       // size: 'hero',
//     },
//   },
// })


// const Button = defineStyleConfig({
//   sizes: {
//     '4xl': defineStyle({
//       h: "24",
//       minW: "24",
//       fontSize: "48px",
//       px: "10",
//       borderRadius: "48px",
//     }),
//   }
// })

// const styles = css({
// const theme = extendTheme({
const theme = {
  // config: {
  //   initialColorMode: 'light',
  //   useSystemColorMode: false,
  // },
  // colors: {
    // grayInv: {
    //   50: "#171923",
    //   100: "#1A202C",
    //   200: "#2D3748",
    //   300: "#4A5568",
    //   400: "#718096",
    //   500: "#A0AEC0",
    //   600: "#CBD5E0",
    //   700: "#E2E8F0",
    //   800: "#EDF2F7",
    //   900: "#F7FAFC",
    // },
  // },
  // styles: {
  primaryColor: 'gray',
  fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
  headings: {
          // properties for all headings
          fontWeight: 400,
  },
  globalStyles: (theme) => ({
    '*': {
      boxSizing: 'border-box',
    },
    'svg *': {
      vectorEffect: 'non-scaling-stroke',
    },
    html: fullSize,
    body: { ...fullSize, margin: 0 },
    '#root': {
      width: '100%',
      height: '100%',
    },
  }),
  // sizes: {
  //   hero: {
  //     fontSize: '5vw',
  //   }
  // },
  // components: {
  //   Heading,
  //   Button
  // },
}

export default theme
