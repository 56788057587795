import { useCallback, useEffect } from 'react'
import { MantineProvider } from '@mantine/core'


const nextFrame = () => new Promise(requestAnimationFrame)


type Point = {x: number, y: number}


import { N_WHITE, N_BLACK, resolvePosition, useBlinds, useDummyBlinds } from '../model/blinds'

import type { Store } from '../model/blinds'

async function drawLoop(canvas: HTMLCanvasElement, signal: AbortSignal) {
  const ctx = canvas.getContext('2d')
  if (!ctx) throw new Error('no canvas context')

  let W = canvas.width = canvas.offsetWidth
  let H = canvas.height = canvas.offsetHeight
  console.log('initial!', W, H)

  const observer = new ResizeObserver(() => {
    W = canvas.width = canvas.offsetWidth
    H = canvas.height = canvas.offsetHeight
    console.log('resize!', W, H)
  })
  observer.observe(canvas)


  const Pt = (x: number, y: number) => ({x: x * W, y: y * H})
  const line = (p1: Point, p2: Point) => {
    ctx.moveTo(p1.x, p1.y)
    ctx.lineTo(p2.x, p2.y)
  }

  while (!signal.aborted) {
    const newt_ms = await nextFrame()
    // const newt = (performance.timeOrigin + newt_ms) / 1000
    const newt = Date.now() / 1000
    const {t, white, black} = useBlinds.getState()

    ctx.clearRect(0, 0, W, H)

    ctx.strokeStyle = '#aaa'
    ctx.lineWidth = 1

    const wmargin = 3 / 30
    const wrange = 1.0 - (wmargin * 2)

    ctx.beginPath()
    for (const [i, blind] of white.entries()) {
      const x = i/(N_WHITE-1) * wrange + wmargin
      const y = resolvePosition(blind, t, newt)

      line(Pt(x, 0), Pt(x, y))
    }

    ctx.stroke()


    ctx.strokeStyle = 'black'
    ctx.lineWidth = 2

    ctx.beginPath()

    const bmargin = 1/6
    const brange = 1.0 - (bmargin * 2)

    for (const [i, blind] of black.entries()) {
      const x = i / (N_BLACK-1) * brange + bmargin
      const x2 = x * wrange + wmargin

      const y = resolvePosition(blind, t, newt)


      line(Pt(x2, 0), Pt(x2, y))
    }

    ctx.stroke()


  }

  console.log('done')
  observer.disconnect()

  // clearInterval(dummyChanger)
}


type BlindsProps = {
  wblinds: number[]
  bblinds: number[]
}

function Blinds({wblinds, bblinds}: BlindsProps) {
  let controller: AbortController | null = null

  useDummyBlinds()

  const ref = useCallback((canvas: HTMLCanvasElement) => {
    if (controller) controller.abort()
    controller = null

    if (canvas) {
      controller = new AbortController()
      drawLoop(canvas, controller.signal)
    }
  }, [])

  return <canvas
    ref={ref}
    style={{
      width: '100%', height: '50%',
      position: 'absolute',
      pointerEvents: 'none'
    }}
    >
  </canvas>
}

export default Blinds
